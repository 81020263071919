import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas'
export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  middleware: [routerMiddleware(history), ...getDefaultMiddleware(), sagaMiddleware],
  reducer: {
      router: connectRouter(history) as any,
  },
});

export type RootState = ReturnType<typeof store.getState>;

