import React, { useState } from 'react';
import './App.css';
const algosdk = require('algosdk');
let waitForConfirmation = require('./algoutils.js').waitForConfirmation;
let printAssetHolding = require('./algoutils.js').printAssetHolding;
let config = require('./config.json');
function App() {
  let [mnemonics, setMno] = useState("");
  let [amount, setAmount] = useState(0);
  let [assetid, setAssetId] = useState(0);
  let [recipient, setrecipient] = useState("");
  let [waiting, setWaiting] = useState(false);
  let [groupTxId, setGrouptTxId] = useState("");

  return (
    <div className="App">
      <header className="App-header">
        <h2>Paste your mnemonic words</h2>
        <textarea value={mnemonics} onChange={(e) => { setMno(e.target.value) }} className="areablock" rows={4} cols={50}></textarea>
        <p>
          <b>
            <label htmlFor="assetid">Asset ID</label>
          </b>
          <input onChange={(e) => setAssetId(Number(e.target.value))} value={assetid} className="inputblock" id="assetid" type="text" />
        </p>
        <p>
          <b>
            <label htmlFor="recipient">Recipient Address</label>
          </b>
          <input onChange={(e) => setrecipient(e.target.value)} value={recipient} className="inputblock" id="recipient" type="text" />
        </p>
        <p>
          <b>
            <label htmlFor="amount ">Set Asset amount to transfer </label>
          </b>
          <input onChange={(e) => setAmount(Number(e.target.value))} value={amount} className="inputblock" id="amount" type="text" />
        </p>
      </header>
      <div>
        <p>
          <button disabled={waiting} className="smallblock" onClick={
            async () => {
              setGrouptTxId("");
              let algodclient = new algosdk.Algodv2(
                {
                  "X-API-Key": config.apiConfig.apiKey
                },
                config.apiConfig.baseUrl,
                '');
              let params = await algodclient.getTransactionParams().do();
              let freezeTarget = recipient;
              let assetID = assetid;
              // We set revocationTarget to undefined as 
              // This is not a clawback operation
              let revocationTarget = undefined;
              // CloseReaminerTo is set to undefined as
              // we are not closing out an asset
              let closeRemainderTo = undefined;
              let manager = algosdk.mnemonicToSecretKey(mnemonics);
              let from = algosdk.mnemonicToSecretKey(mnemonics);
              let uftxn = algosdk.makeAssetFreezeTxnWithSuggestedParams(manager.addr, undefined,
                assetID, freezeTarget, false, params);
              let txn = algosdk.makeAssetTransferTxnWithSuggestedParams(from.addr,
                freezeTarget,
                closeRemainderTo, revocationTarget,
                amount, undefined, assetID, params);
              let ftxn = algosdk.makeAssetFreezeTxnWithSuggestedParams(manager.addr, undefined,
                assetID, freezeTarget, true, params);
              let txns = [uftxn, txn, ftxn];
              let txgroup = algosdk.assignGroupID(txns);

              let signedUftxn = uftxn.signTxn(manager.sk);
              let signedTX = txn.signTxn(from.sk);
              let signedFtxn = ftxn.signTxn(manager.sk);

              let signed = [signedUftxn, signedTX, signedFtxn];
              setWaiting(true);
              let tx = (await algodclient.sendRawTransaction(signed).do());
              let res = await waitForConfirmation(algodclient, tx.txId);
              setGrouptTxId(res);
              setWaiting(false);
            }
          } >Send tokens</button>
        </p>
        <p>
          {waiting && <p style={{ display: "inline-block" }} className="loader"></p>}
          {(groupTxId !== "") && <p>{groupTxId}</p>}
        </p>
      </div>
    </div>
  );
}

export default App;
